import { useRef } from 'react';
import styled from '@emotion/styled';
import MainLogo from '../AuthBlock/MainLogo';
import NavMarketingList from './NavMarketingList';
import UnAuthNavList from './UnAuthNavList';
import { useToggle } from '@hooks/toggle/useToggle';
import useHandleOutsideClickAction from '@hooks/interaction/useHandleOutsideClickAction';
import Icon from '@components/_atoms/Icon';
import { black, gray, white } from '@styles/Colors';
import RefIcon from '@components/_molecules/RefIcon';
import LanguageModal from '../Modals/LanguageModal';

type Props = {
  readonly isHomeTop: boolean;
};

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Right = styled.div`
  height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

const DividerIcon = styled(Icon)``;
const LanguageIcon = styled(RefIcon)``;

export enum UnAuthHeaderModalType {
  Language = 'language',
}

export default function UnAuthBlock({ isHomeTop }: Props) {
  const languageIconRef = useRef<HTMLDivElement>(null);
  const languageModalRef = useRef<HTMLDivElement>(null);

  const languageModal = useToggle();

  // language icon 클릭
  const onClickIcon = (modalType: UnAuthHeaderModalType) => {
    if (modalType === UnAuthHeaderModalType.Language) {
      languageModal.toggle();
    }
  };

  const closeLanguageModal = () => {
    languageModal.off();
  };

  // 외부클릭 처리
  useHandleOutsideClickAction([languageIconRef, languageModalRef], closeLanguageModal);

  return (
    <HeaderContent>
      <Left>
        <MainLogo isHomeTop={isHomeTop} />

        {/* table mode none */}
        <NavMarketingList isHomeTop={isHomeTop} />
      </Left>
      <Right>
        <UnAuthNavList isHomeTop={isHomeTop} />
        <DividerIcon name="divider" fill={gray.gray4} stroke={gray.gray4} />
        <LanguageIcon
          name="language"
          stroke={isHomeTop ? white : black}
          onClick={() => onClickIcon(UnAuthHeaderModalType.Language)}
          ref={languageIconRef}
        />
      </Right>

      <LanguageModal isOpen={languageModal.isToggled} ref={languageModalRef} />
    </HeaderContent>
  );
}
