import Icon from '@components/_atoms/Icon';
import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import { SIGNUP } from '@constants/routes/routes';
import { LAPTOP_MODE } from '@constants/size';
import styled from '@emotion/styled';
import { useIsLaptop, useIsTablet } from '@hooks/responsive/useIsMobile';
import { white } from '@styles/Colors';
import { getCookie, setCookie } from '@utils/cookie';
import { Time } from '@utils/time';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type Props = {
  loginStatus: boolean;
};

const Layout = styled.div`
  height: 86px;
  background-color: #111524;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  column-gap: 44px;

  @media ${LAPTOP_MODE} {
    column-gap: 24px;
  }
`;

const Text = styled.span`
  font-weight: 400;
`;

const CloseBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
`;

// only PC
export default function Banner({ loginStatus }: Props) {
  const router = useRouter();
  const { i18n } = useTranslation();
  const ko = i18n.language === 'ko';
  const HIDE_BANNER_FOR_TODAY = 'hideBannerForToday';
  const hideBannerForToday = getCookie(HIDE_BANNER_FOR_TODAY);

  const isLaptop = useIsLaptop();
  const isTablet = useIsTablet();

  const [open, setOpen] = useState(false);

  // 배너 set
  useEffect(() => {
    if (router.pathname !== '/') return; // Home에서만 노출
    if (!ko) return; // ko에서만 노출

    if (!hideBannerForToday && !loginStatus) {
      setOpen(true);
      return;
    }
  }, [loginStatus, router, ko]);

  // 배너 close
  const closeBanner = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    const today = Time();
    const tomorrow = Time().add(1, 'day').startOf('day');
    const maxAge = tomorrow.diff(today, 'second');

    setCookie(HIDE_BANNER_FOR_TODAY, today, { maxAge });
    setOpen(false);
  };

  return (
    <>
      {!isTablet && open && (
        <Layout onClick={() => router.push(SIGNUP)}>
          <Content>
            <Typography variant={TypoVariant.SH2} color={white}>
              {isLaptop ? '7/17일 ~ 8/31' : '7월 17일 ~ 8월 31일'}
            </Typography>
            <Image src={`/assets/images/banner-gift.png`} width={56} height={56} alt="banner-gift" />
            <Typography variant={TypoVariant.SH2} color={white}>
              {isLaptop ? (
                <>
                  <Text>회원가입 이벤트</Text> 커피쿠폰, 주유권, 굿즈 <Text>랜덤발송!</Text>
                </>
              ) : (
                <>
                  스타벅스 커피쿠폰, 3만원 주유권, 스틸보소 굿즈 <Text>중 랜덤발송</Text>
                </>
              )}
            </Typography>
            <Image src={`/assets/images/banner-arrow.png`} width={30} height={30} alt="banner-arrow" />
            <CloseBox onClick={closeBanner}>
              {!isLaptop && (
                <Typography variant={TypoVariant.B2} color={white}>
                  오늘 하루 보지 않기
                </Typography>
              )}
              <Icon onClick={() => {}} name="close" stroke={white} />
            </CloseBox>
          </Content>
        </Layout>
      )}
    </>
  );
}
