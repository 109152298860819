import Icon from '@components/_atoms/Icon';
import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import { LOGOUT, MYPAGE } from '@constants/routes/routes';
import styled from '@emotion/styled';
import { gray, primary, white } from '@styles/Colors';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { ForwardedRef, forwardRef } from 'react';
import { Radius } from '@styles/Radius';
import { Shadow } from '@styles/Shadow';
import Divider from '@components/_atoms/Divider';
import { useToggle } from '@hooks/toggle/useToggle';
import NotiOptionList from './NotiOptionList';
import { useGetUserProfileFetch } from '@hooks/fetch/useUserFetch';

type Props = {
  readonly isOpen: boolean;
};

const ProfileModalContainer = styled.div<{ isOpen: boolean }>`
  width: 272px;
  padding: 24px;
  background: ${white};
  border-radius: ${Radius.MEDIUM};
  box-shadow: ${Shadow.MEDIUM};
  position: absolute;
  top: 64px;
  right: 80px;

  display: grid;
  row-gap: 24px;

  ${(props) =>
    props.isOpen === false &&
    `
      display:none;
    `}
`;
const UserIcon = styled(Icon)``;

const ProfileName = styled.div``;

const ProfileMenu = styled.div`
  display: grid;
  row-gap: 8px;
`;

const ProfileMenuItem = styled.div`
  padding: 8px;

  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const ProfileLogoutButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ProfileModal = ({ isOpen }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const { t } = useTranslation('common');

  const selectNavItem = useToggle();

  const { getUserProfileFetchData: user } = useGetUserProfileFetch();

  return (
    <ProfileModalContainer isOpen={isOpen} ref={ref}>
      <ProfileName>
        <Typography variant={TypoVariant.SH3} color={primary.gray}>
          {t('header.profileModal.title1-1')}
          {user?.name}
          {t('header.profileModal.title1-2')}
        </Typography>
      </ProfileName>
      <ProfileMenu>
        <Link href={MYPAGE}>
          <a>
            <ProfileMenuItem>
              <UserIcon name="user" stroke={primary.gray} />
              <Typography variant={TypoVariant.B4} color={primary.gray}>
                {t('header.profileModal.profile')}
              </Typography>
            </ProfileMenuItem>
          </a>
        </Link>
        <NotiOptionList isOpen={selectNavItem.isToggled} onClick={() => selectNavItem.toggle()} />
      </ProfileMenu>
      <Divider />
      <Link href={LOGOUT}>
        <a>
          <ProfileLogoutButtonBox>
            <Typography variant={TypoVariant.SH4} color={gray.gray6}>
              {t('header.profileModal.logout')}
            </Typography>
          </ProfileLogoutButtonBox>
        </a>
      </Link>
    </ProfileModalContainer>
  );
};

export default forwardRef(ProfileModal);
