import {
  BUYER,
  CHANNEL_TALK,
  COMPANY_HIRE_NOTION,
  COMPANY_INTRO,
  ESTIMATE_FILE,
  FACEBOOK_EN,
  FACEBOOK_KO,
  LINKED_IN,
  MEDIUM,
  NAVER_BLOG,
  SELLER,
  SIGNUP,
} from '@constants/routes/routes';
import styled from '@emotion/styled';
import { useState } from 'react';
import LanguageOptionList from '../../LanguageOptionList';
import AccodianNavItem from './AccodianNavItem';
import { useTranslation } from 'next-i18next';

const Layout = styled.div`
  padding: 24px 0;

  display: grid;
  row-gap: 8px;
`;

const ChatNavItem = styled(AccodianNavItem)``;
const CommunicationNavItem = styled(AccodianNavItem)``;
const SupportNavItem = styled(AccodianNavItem)``;
const ServiceNavItem = styled(AccodianNavItem)``;

export enum UnAuthSelectNavItemType {
  Intro = 'intro',
  Communication = 'communication',
  Support = 'support',
  Service = 'service',
  Language = 'language',
}

export default function UnAuthNavLinkList() {
  const { t, i18n } = useTranslation('common');
  const ko = i18n.language === 'ko';
  const [selectNavItem, setSelectNavItem] = useState<UnAuthSelectNavItemType | ''>('');

  const handleClickNavItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.target as HTMLButtonElement;

    if (value === selectNavItem) {
      setSelectNavItem('');
    } else {
      if (
        value === UnAuthSelectNavItemType.Intro ||
        value === UnAuthSelectNavItemType.Communication ||
        value === UnAuthSelectNavItemType.Support ||
        value === UnAuthSelectNavItemType.Service ||
        value === UnAuthSelectNavItemType.Language
      ) {
        setSelectNavItem(value);
      }
    }
  };

  return (
    <Layout>
      <ChatNavItem
        iconName="logo-black"
        title={t('header.marketing.title1')}
        content={[
          { link: COMPANY_INTRO, content: t('header.marketing.content1-1') },
          { link: COMPANY_HIRE_NOTION, content: t('header.marketing.content1-2') },
        ]}
        select={selectNavItem === UnAuthSelectNavItemType.Intro}
        value={UnAuthSelectNavItemType.Intro}
        onClick={handleClickNavItem}
      />
      <CommunicationNavItem
        iconName="chat"
        title={t('header.marketing.title2')}
        content={[
          { link: NAVER_BLOG, content: t('header.marketing.content2-1') },
          { link: ko ? FACEBOOK_KO : FACEBOOK_EN, content: t('header.marketing.content2-2') },
          { link: LINKED_IN, content: t('header.marketing.content2-3') },
          { link: MEDIUM, content: t('header.marketing.content2-4') },
        ]}
        select={selectNavItem === UnAuthSelectNavItemType.Communication}
        value={UnAuthSelectNavItemType.Communication}
        onClick={handleClickNavItem}
      />
      <SupportNavItem
        iconName="alert-more"
        title={t('header.marketing.title3')}
        content={[
          { link: CHANNEL_TALK, content: t('header.marketing.content3-1') },
          { link: ESTIMATE_FILE, content: t('header.marketing.content3-2') },
        ]}
        select={selectNavItem === UnAuthSelectNavItemType.Support}
        value={UnAuthSelectNavItemType.Support}
        onClick={handleClickNavItem}
      />
      <ServiceNavItem
        iconName="project"
        title={t('header.marketing.title4')}
        content={[
          { link: BUYER, content: t('header.marketing.content4-1') },
          { link: SELLER, content: t('header.marketing.content4-2') },
        ]}
        select={selectNavItem === UnAuthSelectNavItemType.Service}
        value={UnAuthSelectNavItemType.Service}
        onClick={handleClickNavItem}
      />
      <LanguageOptionList isOpen={selectNavItem === UnAuthSelectNavItemType.Language} onClick={handleClickNavItem} />
    </Layout>
  );
}
