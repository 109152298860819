import { HOME } from '@constants/routes/routes';
import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import DefaultLogo from '@public/assets/common/logo-d.webp';
import WhiteLogo from '@public/assets/common/logo-w.webp';

type Props = {
  readonly isHomeTop?: boolean;
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

const MainLogoImage = styled(Image)``;

export default function MainLogo({ isHomeTop: isScrollToTop }: Props) {
  return (
    <Layout>
      <Link href={HOME}>
        <a>
          <MainLogoImage src={isScrollToTop ? WhiteLogo : DefaultLogo} width={120} height={20} alt="main-logo" />
        </a>
      </Link>
    </Layout>
  );
}
