import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import styled from '@emotion/styled';
import { black, primary, white } from '@styles/Colors';
import Link from 'next/link';
import { useRouter } from 'next/router';

type Props = {
  readonly link: string;
  readonly title: string;
  readonly isHomeTop: boolean;
};

const Layout = styled.div<{ isCurrent: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 0 8px;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.isCurrent &&
    `
    padding-top:3px;
    border-bottom: 3px solid ${primary.blue};
  `}
`;
const TypoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default function NavItem({ link, title, isHomeTop }: Props) {
  const router = useRouter();
  const isCurrent = router.pathname.startsWith(link);

  const onClick = () => {
    router.push(link);
  };

  return (
    <Layout isCurrent={isCurrent} onClick={onClick}>
      <Link href={link}>
        <a>
          <TypoWrapper>
            <Typography style={{ cursor: 'pointer' }} variant={TypoVariant.SH4} color={isHomeTop ? white : black}>
              {title}
            </Typography>
          </TypoWrapper>
        </a>
      </Link>
    </Layout>
  );
}
