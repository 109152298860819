import { LOGIN, SIGNUP } from '@constants/routes/routes';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import NavItem from './NavItem';

type Props = {
  readonly isHomeTop: boolean;
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

export default function UnAuthNavList({ isHomeTop }: Props) {
  const { t } = useTranslation('common');
  return (
    <Layout>
      <NavItem title={t('header.signup')} link={SIGNUP} isHomeTop={isHomeTop} />
      <NavItem title={t('header.login')} link={LOGIN} isHomeTop={isHomeTop} />
    </Layout>
  );
}
