import styled from '@emotion/styled';
import Link from 'next/link';

// components
import Typography from '@components/_atoms/Typography';
import MainLogo from './MainLogo';

// constants
import { gray, primary } from '@styles/Colors';
import { TypoVariant } from '@constants/atoms';
import { TABLET_MODE } from '@constants/size';
import {
  CHANNEL_TALK,
  COMPANY_HIRE_NOTION,
  COMPANY_INTRO,
  COMPANY_MARKETING,
  COMPANY_POLICY,
  COMPANY_USE,
  ESTIMATE_FILE,
  FACEBOOK_EN,
  FACEBOOK_KO,
  LINKED_IN,
  MEDIUM,
  NAVER_BLOG,
} from '@constants/routes/routes';
import { useTranslation } from 'next-i18next';

const Layout = styled.footer`
  max-width: 1120px;
  padding: 80px 20px;
  margin: 0 auto;

  display: grid;
  row-gap: 48px;
`;

const Top = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  column-gap: 24px;

  @media ${TABLET_MODE} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 24px;
  }
`;

const Section = styled.div`
  @media ${TABLET_MODE} {
    /* only Logo Section */
    &:nth-of-type(1) {
      grid-column: 1/5;
    }
  }
`;

const ListBox = styled.div<{ isLogoList?: boolean }>`
  margin-top: 16px;

  display: grid;
  row-gap: 12px;

  ${({ isLogoList }) =>
    isLogoList &&
    `
    row-gap:8px;
  `}
`;

const Bottom = styled.div`
  padding-top: 20px;
  border-top: 1px solid ${gray.gray3};

  display: grid;
  row-gap: 16px;
`;

export default function Footer() {
  const { t, i18n } = useTranslation('common');
  const ko = i18n.language === 'ko';
  const STEELBOSO_EMAIL = 'mailto:support@steelboso.com';

  return (
    <Layout>
      <Top>
        <Section>
          {/* MainLogo */}
          <MainLogo />
          {/* MainLogo end */}
          <ListBox isLogoList>
            <Typography variant={TypoVariant.B3} color={primary.gray}>
              {t('footer.content1-1')}
              <br />
              {t('footer.content1-2')}
            </Typography>
            <Typography variant={TypoVariant.B5} color={gray.gray7}>
              {t('footer.content1-3')}
              <br />
              {t('footer.content1-4')}
            </Typography>
          </ListBox>
        </Section>
        <Section>
          <Typography variant={TypoVariant.SH3} color={primary.gray}>
            {t('footer.menu2')}
          </Typography>
          <ListBox>
            <a href={CHANNEL_TALK} target="_blank" rel="noreferrer">
              <Typography variant={TypoVariant.B4} color={gray.gray7}>
                {t('footer.content2-1')}
              </Typography>
            </a>
            <a href={STEELBOSO_EMAIL}>
              <Typography variant={TypoVariant.B4} color={gray.gray7}>
                {t('footer.content2-2')}
              </Typography>
            </a>
            <a href={ESTIMATE_FILE} target="_blank" rel="noreferrer">
              <Typography variant={TypoVariant.B4} color={gray.gray7}>
                {t('footer.content2-3')}
              </Typography>
            </a>
          </ListBox>
        </Section>
        <Section>
          <Typography variant={TypoVariant.SH3} color={primary.gray}>
            {t('footer.menu3')}
          </Typography>
          <ListBox>
            <Link href={COMPANY_INTRO}>
              <a>
                <Typography variant={TypoVariant.B4} color={gray.gray7}>
                  {t('footer.content3-1')}
                </Typography>
              </a>
            </Link>
            <a href={COMPANY_HIRE_NOTION} target="_blank" rel="noreferrer">
              <Typography variant={TypoVariant.B4} color={gray.gray7}>
                {t('footer.content3-2')}
              </Typography>
            </a>
          </ListBox>
        </Section>
        <Section>
          <Typography variant={TypoVariant.SH3} color={primary.gray}>
            {t('footer.menu4')}
          </Typography>
          <ListBox>
            <a href={NAVER_BLOG} target="_blank" rel="noreferrer">
              <Typography variant={TypoVariant.B4} color={gray.gray7}>
                {t('footer.content4-1')}
              </Typography>
            </a>
            <a href={ko ? FACEBOOK_KO : FACEBOOK_EN} target="_blank" rel="noreferrer">
              <Typography variant={TypoVariant.B4} color={gray.gray7}>
                {t('footer.content4-2')}
              </Typography>
            </a>
            <a href={LINKED_IN} target="_blank" rel="noreferrer">
              <Typography variant={TypoVariant.B4} color={gray.gray7}>
                {t('footer.content4-3')}
              </Typography>
            </a>
            <a href={MEDIUM} target="_blank" rel="noreferrer">
              <Typography variant={TypoVariant.B4} color={gray.gray7}>
                {t('footer.content4-4')}
              </Typography>
            </a>
          </ListBox>
        </Section>
        <Section>
          <Typography variant={TypoVariant.SH3} color={primary.gray}>
            {t('footer.menu5')}
          </Typography>
          <ListBox>
            <Link href={COMPANY_USE}>
              <a target="_blank">
                <Typography variant={TypoVariant.B4} color={gray.gray7}>
                  {t('footer.content5-1')}
                </Typography>
              </a>
            </Link>
            <Link href={COMPANY_POLICY}>
              <a>
                <Typography variant={TypoVariant.B4} color={gray.gray7}>
                  {t('footer.content5-2')}
                </Typography>
              </a>
            </Link>
            <Link href={COMPANY_MARKETING}>
              <a>
                <Typography variant={TypoVariant.B4} color={gray.gray7}>
                  {t('footer.content5-3')}
                </Typography>
              </a>
            </Link>
          </ListBox>
        </Section>
      </Top>
      <Bottom>
        <Typography variant={TypoVariant.B5} color={gray.gray5}>
          Market Of Material Co.,Ltd. | HQ Address: 114BD-301, UNIST, 50, UNIST-gil, Eonyang-eup, Ulju-gun, Ulsan,
          Republic of Korea | Lab Address: Company Support Hub P802, 815, Daewangpangyo-ro, Sujeong-gu, Seongnam-si,
          Gyeonggi-do, Republic of Korea | CEO: Jo Yun Ki | Business Registration Number: 435-86-01645 | Number for
          mail-order sale: 제2021-울산울주-0551호 | Tel: +82-1670-1621
        </Typography>
        <Typography variant={TypoVariant.B5} color={gray.gray5}>
          Copyright ⓒ Marketofmaterial Inc. All Rights Reserved.
        </Typography>
      </Bottom>
    </Layout>
  );
}
