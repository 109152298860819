import Icon from '@components/_atoms/Icon';
import RefIcon from '@components/_molecules/RefIcon';
import styled from '@emotion/styled';
import { useToggle } from '@hooks/toggle/useToggle';
import { useRef } from 'react';

// components
import LanguageModal from '../Modals/LanguageModal';
import PushModal from '../Modals/PushModal';
import ProfileModal from '../Modals/ProfileModal';
import AuthNavList from './AuthNavList';
import MainLogo from './MainLogo';

// constatns
import { black, gray, white } from '@styles/Colors';

// hooks
import useHandleOutsideClickAction from '@hooks/interaction/useHandleOutsideClickAction';

type Props = {
  readonly isHomeTop: boolean;
};

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Right = styled.div`
  height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

const DividerIcon = styled(Icon)``;
const LanguageIcon = styled(RefIcon)``;

export enum AuthHeaderModalType {
  Profile = 'profile',
  Push = 'push',
  Language = 'language',
}

export default function AuthBlock({ isHomeTop }: Props) {
  const pushIconRef = useRef<HTMLDivElement>(null);
  const profileIconRef = useRef<HTMLDivElement>(null);
  const languageIconRef = useRef<HTMLDivElement>(null);

  const pushModalRef = useRef<HTMLDivElement>(null);
  const profileModalRef = useRef<HTMLDivElement>(null);
  const languageModalRef = useRef<HTMLDivElement>(null);

  const profileModal = useToggle();
  const pushModal = useToggle();
  const languageModal = useToggle();

  const onClickIcon = (modalType: AuthHeaderModalType) => {
    const modalObject = {
      [AuthHeaderModalType.Profile]: profileModal,
      [AuthHeaderModalType.Push]: pushModal,
      [AuthHeaderModalType.Language]: languageModal,
    };

    Object.entries(modalObject).map(([type, modal]) => {
      if (type === modalType) {
        modal.toggle();
      } else {
        modal.off();
      }
    });
  };

  const closeAllModal = () => {
    const modals = [profileModal, pushModal, languageModal];

    modals.map((modal) => {
      modal.off();
    });
  };

  // 외부클릭 처리
  useHandleOutsideClickAction(
    [profileIconRef, pushIconRef, languageIconRef, profileModalRef, pushModalRef, languageModalRef],
    closeAllModal,
  );

  return (
    <HeaderContent>
      <Left>
        <MainLogo isHomeTop={isHomeTop} />
      </Left>
      <Right>
        <AuthNavList
          isHomeTop={isHomeTop}
          onClickIcon={onClickIcon}
          profileIconRef={profileIconRef}
          pushIconRef={pushIconRef}
        />
        <DividerIcon name="divider" fill={gray.gray4} stroke={gray.gray4} />
        <LanguageIcon
          name="language"
          stroke={isHomeTop ? white : black}
          onClick={() => onClickIcon(AuthHeaderModalType.Language)}
          ref={languageIconRef}
        />
      </Right>

      {/* Modal */}
      <PushModal isOpen={pushModal.isToggled} ref={pushModalRef} />
      <ProfileModal isOpen={profileModal.isToggled} ref={profileModalRef} />
      <LanguageModal isOpen={languageModal.isToggled} ref={languageModalRef} />
      {/* Modal end */}
    </HeaderContent>
  );
}
