import Icon from '@components/_atoms/Icon';
import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import { MONTH } from '@constants/time';
import styled from '@emotion/styled';
import { RecoilLanguage } from '@recoil/language';
import { blue, primary } from '@styles/Colors';
import { LANGUAGE, setCookie } from '@utils/cookie';
import { useRouter } from 'next/router';
import { UnAuthSelectNavItemType } from '../UnAuthMenuContent/UnAuthNavLinkList';
import { useTranslation } from 'next-i18next';

type Props = {
  readonly isOpen: boolean;
  readonly onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Layout = styled.div`
  padding: 8px;
`;

const TitleContainer = styled.button`
  width: 100%;

  border: none;
  background: none;

  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const Left = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const LanguageIcon = styled(Icon)``;
const ArrowIcon = styled(Icon)``;

const ContentContainer = styled.div`
  margin-top: 8px;
  padding-left: 36px;
`;

const ContentItem = styled.div<{ select?: boolean }>`
  padding: 8px;
  cursor: pointer;

  ${({ select }) =>
    select &&
    `
    background: ${blue.blue1}
  `}
`;

export default function LanguageOptionList({ isOpen, onClick }: Props) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const locale = router.locale;
  const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;

  const onSelectLanguage = (langVal: RecoilLanguage) => {
    setCookie(LANGUAGE, langVal, { maxAge: 3 * MONTH, domain: currentDomain, path: '/' });
    router.push(`/${langVal}${router.asPath}`, `/${langVal}${router.asPath}`, { locale: langVal });
  };

  return (
    <Layout>
      <TitleContainer onClick={onClick} value={UnAuthSelectNavItemType.Language}>
        <Left>
          <LanguageIcon name="language" stroke={primary.gray} />
          <Typography variant={TypoVariant.SH3} color={primary.gray}>
            {t('header.mobile.list6')}
          </Typography>
        </Left>
        <ArrowIcon name={isOpen ? 'arrow-up' : 'arrow-down'} stroke={primary.gray} style={{ pointerEvents: 'none' }} />
      </TitleContainer>

      {/* only isOpen */}
      {isOpen && (
        <ContentContainer>
          <ContentItem select={locale === RecoilLanguage.KO} onClick={() => onSelectLanguage(RecoilLanguage.KO)}>
            <Typography variant={TypoVariant.B4} color={locale === RecoilLanguage.KO ? primary.blue : primary.gray}>
              한국어
            </Typography>
          </ContentItem>
          <ContentItem select={locale === RecoilLanguage.EN} onClick={() => onSelectLanguage(RecoilLanguage.EN)}>
            <Typography variant={TypoVariant.B4} color={locale === RecoilLanguage.EN ? primary.blue : primary.gray}>
              English
            </Typography>
          </ContentItem>
        </ContentContainer>
      )}
    </Layout>
  );
}
