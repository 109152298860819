import styled from '@emotion/styled';
import React from 'react';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Toast } from '@components/_atoms/Toast';

// components
import PushItem from '@components/_organism/push/PushItem';
import Typography from '@components/_atoms/Typography';
import TextButton from '@components/_atoms/TextButton';
import Divider from '@components/_atoms/Divider';

// constants
import { gray, white } from '@styles/Colors';
import { Radius } from '@styles/Radius';
import { Shadow } from '@styles/Shadow';
import { PUSH } from '@constants/routes/routes';
import { TextButtonColor, TypoVariant } from '@constants/atoms';

// hooks
import { useGetPushFetch, usePatchAllPushFetch } from '@hooks/fetch/usePushFetch';
import { useQueryClient } from 'react-query';

type Props = {
  readonly isOpen: boolean;
};

const Layout = styled.div<{ isOpen: boolean }>`
  width: 100%;
  padding: 24px;
  background: ${white};
  border-radius: ${Radius.MEDIUM};
  box-shadow: ${Shadow.MEDIUM};
  position: absolute;
  top: 64px;

  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isOpen === false &&
    `
      display:none;
    `}

  top: 64px;
  right: 80px;

  width: 360px;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const Content = styled.div`
  margin-top: 24px;

  display: flex;
  flex-direction: column;
`;

const DividerWrapper = styled.div`
  margin-top: 24px;
`;

const TextButtonWrapper = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const EmptyContent = styled.div`
  height: 520px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const PushModal = ({ isOpen }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');

  const { getPushFetchData } = useGetPushFetch();
  const pushData = getPushFetchData?.pages.flatMap((pageData) => pageData?.results);

  const { patchAllPushMutate } = usePatchAllPushFetch();

  const handleClickAllPatch = () => {
    patchAllPushMutate(void 0, {
      onSuccess: () => {
        Toast.success(t('header.pushModal.success-message'));
        queryClient.invalidateQueries(['getPush']);
      },
    });
  };

  return (
    <Layout ref={ref} isOpen={isOpen}>
      <Head>
        <Typography variant={TypoVariant.SH2}>{t('header.pushModal.title')}</Typography>
        <Typography style={{ cursor: 'pointer' }} variant={TypoVariant.B4} onClick={handleClickAllPatch}>
          {t('header.pushModal.all-patch-button')}
        </Typography>
      </Head>

      {!!pushData?.length ? (
        <>
          <Content>
            {pushData?.map((item, idx) => (
              <React.Fragment key={item?.id}>{idx < 3 && <PushItem data={item!} />}</React.Fragment>
            ))}
          </Content>

          <DividerWrapper>
            <Divider />
          </DividerWrapper>

          <TextButtonWrapper>
            <Link href={PUSH}>
              <a>
                <TextButton label={t('header.pushModal.more-button')} color={TextButtonColor.Gray} />
              </a>
            </Link>
          </TextButtonWrapper>
        </>
      ) : (
        <EmptyContent>
          <Typography variant={TypoVariant.B3} color={gray.gray4}>
            {t('header.pushModal.empty-content')}
          </Typography>
        </EmptyContent>
      )}
    </Layout>
  );
};

export default forwardRef(PushModal);
