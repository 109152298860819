import styled from '@emotion/styled';
import Link from 'next/link';
import Image from 'next/image';

// components
import MainLogo from '../Footer/MainLogo';
import Typography from '@components/_atoms/Typography';

// constants
import { TypoVariant } from '@constants/atoms';
import { gray, primary } from '@styles/Colors';
import { FACEBOOK_EN, FACEBOOK_KO, LINKED_IN, MEDIUM, NAVER_BLOG } from '@constants/routes/routes';
import { useTranslation } from 'next-i18next';

const Layout = styled.footer`
  width: 320px;
  margin: 0 auto;
  padding: 48px 0;
`;

const MobileFooterContent = styled.div`
  margin: 16px 0 32px;

  display: grid;
  row-gap: 8px;
`;

const SnsBox = styled.div`
  display: flex;
  column-gap: 16px;
`;

const SnsButtonWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
`;

export default function MobileFooter() {
  const { i18n } = useTranslation();
  const ko = i18n.language === 'ko';
  return (
    <Layout>
      {/* MainLogo */}
      <MainLogo />
      {/* MainLogo end */}

      <MobileFooterContent>
        <Typography variant={TypoVariant.B2} color={primary.gray}>
          {ko ? (
            <>
              궁금하신 점은 우측 하단 채널톡 이나
              <br /> 전화 문의(1670-1621)로 안내해드리겠습니다.
            </>
          ) : (
            <>For inquiries, please click the ‘Chat Assistant’ icon on the lower right corner.</>
          )}
        </Typography>
        <Typography variant={TypoVariant.B4} color={gray.gray7}>
          {ko ? (
            <>
              평일 오전 9시 ~ 오후 6시
              <br />
              (점심시간 오후 12시 ~ 오후 1시, 주말/공휴일 제외)
            </>
          ) : (
            <>
              Weekdays 9am to 6pm
              <br />
              (UTC+9, Korea Standard Time)
              <br />
              (Lunch 12pm - 1pm, excluding weekends/holidays)
            </>
          )}
        </Typography>
      </MobileFooterContent>

      <SnsBox>
        <a href={NAVER_BLOG} target="_blank" rel="noreferrer">
          <SnsButtonWrapper>
            <Image src="/assets/images/Naver.svg" layout="fill" alt="naver" />
          </SnsButtonWrapper>
        </a>
        <a href={ko ? FACEBOOK_KO : FACEBOOK_EN} target="_blank" rel="noreferrer">
          <SnsButtonWrapper>
            <Image src="/assets/images/Facebook.svg" layout="fill" alt="facebook" />
          </SnsButtonWrapper>
        </a>
        <a href={LINKED_IN} target="_blank" rel="noreferrer">
          <SnsButtonWrapper>
            <Image src="/assets/images/Linkedin.svg" layout="fill" alt="linkedIn" />
          </SnsButtonWrapper>
        </a>
        <a href={MEDIUM} target="_blank" rel="noreferrer">
          <SnsButtonWrapper>
            <Image src="/assets/images/Medium.svg" layout="fill" alt="medium" />
          </SnsButtonWrapper>
        </a>
      </SnsBox>
    </Layout>
  );
}
