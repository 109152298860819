import styled from '@emotion/styled';
import { HOME } from '@constants/routes/routes';
import { useCheckScrollToTop } from '@hooks/interaction/useCheckScrollToTop';
import { gray, white } from '@styles/Colors';
import { useRouter } from 'next/router';
import UnAuthBlock from './UnAuthBlock';
import AuthBlock from './AuthBlock';

import { useGetUserProfileFetch } from '@hooks/fetch/useUserFetch';
import Banner from '../Banner';

const Layout = styled.header<{ isHomeTop: boolean }>`
  /* position: fixed; */
  /* top: 0; */
  width: 100%;
  height: 56px;
  padding: 0 24px;
  /* z-index: 999; */
  background: transparent;
  user-select: none;

  transition: all 0.15s ease-in-out;

  /* scroll */
  ${(props) =>
    !props.isHomeTop &&
    `
    background: ${white};
    box-shadow: 0px 2px 4px 0px #80808014;
    border-bottom: 1px solid ${gray.gray2};
  `}
`;

export default function Header() {
  const router = useRouter();
  const isHome = router.pathname === HOME;
  const isScrollToTop = useCheckScrollToTop();
  const isHomeTop = isHome && isScrollToTop;

  const { getUserProfileFetchData: user, getUserProfileIsLoading } = useGetUserProfileFetch();
  const loginStatus = !!user;

  return (
    <>
      <Banner loginStatus={loginStatus || getUserProfileIsLoading} />
      <Layout isHomeTop={isHomeTop}>
        {loginStatus ? <AuthBlock isHomeTop={isHomeTop} /> : <UnAuthBlock isHomeTop={isHomeTop} />}
      </Layout>
    </>
  );
}
