import styled from '@emotion/styled';
import { primary, white } from '@styles/Colors';
import { useState } from 'react';
import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import DefaultModal, { DefaultModalType } from '@components/_organism/Header/Modals/DefaultModal';

type Props = {
  readonly isHomeTop: boolean;
  readonly title: string;
  readonly type: DefaultModalType;
};

const Layout = styled.div`
  height: 56px;
  padding: 0 9px;

  display: flex;
  align-items: center;
  position: relative;
`;

const Space = styled.div`
  width: 100%;
  height: 8px;
  position: absolute;
  top: 56px;
  left: 0;
`;

export default function MarketingItem({ isHomeTop, title, type }: Props) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Layout
      style={{ cursor: 'pointer' }}
      onMouseEnter={() => setOpenModal(true)}
      onMouseLeave={() => setOpenModal(false)}>
      <Typography style={{ pointerEvents: 'none' }} variant={TypoVariant.SH4} color={isHomeTop ? white : primary.gray}>
        {title}
      </Typography>
      <Space />
      <DefaultModal isOpen={openModal} type={type} />
    </Layout>
  );
}
