import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import { LOGOUT } from '@constants/routes/routes';
import styled from '@emotion/styled';
import { gray } from '@styles/Colors';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import AuthNavLinkList from './AuthNavLinkList';
import Title from './Title';

const Layout = styled.div``;
const LogoutButtonContainer = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
`;
const LogoutButton = styled(Typography)``;

export default function AuthMenuContent() {
  const { t } = useTranslation('common');

  return (
    <Layout>
      <Title />
      <AuthNavLinkList />
      <Link href={LOGOUT}>
        <LogoutButtonContainer>
          <LogoutButton variant={TypoVariant.B3} color={gray.gray6}>
            {t('header.mobile.logout')}
          </LogoutButton>
        </LogoutButtonContainer>
      </Link>
    </Layout>
  );
}
