import Typography from '@components/_atoms/Typography';
import { TypoVariant } from '@constants/atoms';
import { MONTH } from '@constants/time';
import styled from '@emotion/styled';
import { RecoilLanguage } from '@recoil/language';
import { gray, primary, white } from '@styles/Colors';
import { Radius } from '@styles/Radius';
import { Shadow } from '@styles/Shadow';
import { LANGUAGE, setCookie } from '@utils/cookie';
import { useRouter } from 'next/router';
import { ForwardedRef, forwardRef } from 'react';

type Props = {
  readonly isOpen: boolean;
};

const LanguageModalContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  padding: 24px;
  background: ${white};
  border-radius: ${Radius.MEDIUM};
  box-shadow: ${Shadow.MEDIUM};
  position: absolute;
  top: 64px;

  display: flex;
  flex-direction: column;

  ${({ isOpen }) =>
    !isOpen &&
    `
    display:none;
  `}

  width: 120px;
  height: 108px;

  top: 64px;
  right: 24px;
`;
const LanguageItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
`;

const LanguageModal = ({ isOpen }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const router = useRouter();
  const locale = router.locale;
  const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;

  const handleSotrageLanguageSet = (langVal: RecoilLanguage) => {
    setCookie(LANGUAGE, langVal, { maxAge: 3 * MONTH, domain: currentDomain, path: '/' });
    router.push(`/${langVal}${router.asPath}`, `/${langVal}${router.asPath}`, { locale: langVal });
  };

  return (
    <LanguageModalContainer isOpen={isOpen} ref={ref}>
      <LanguageItem onClick={() => handleSotrageLanguageSet(RecoilLanguage.KO)}>
        <Typography variant={TypoVariant.SH3} color={locale === RecoilLanguage.KO ? primary.blue : gray.gray5}>
          한국어
        </Typography>
      </LanguageItem>
      <LanguageItem onClick={() => handleSotrageLanguageSet(RecoilLanguage.EN)}>
        <Typography variant={TypoVariant.SH3} color={locale === RecoilLanguage.EN ? primary.blue : gray.gray5}>
          English
        </Typography>
      </LanguageItem>
    </LanguageModalContainer>
  );
};

export default forwardRef(LanguageModal);
